import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import install from './components'
import "./style/common.css"
import MyPlugin from "@/utils/print"

//main.js
import Print from 'vue-print-nb'
Vue.use(Print);


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
Vue.use(install);
Vue.use(MyPlugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')