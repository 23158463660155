import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: "/auth",
  },
  {
    path: "/login",
    name: "login",
    // 登录
    component: () => import("@/views/NoAuth/Login.vue")
  },
  {
    path: '/auth',
    component: () => import("@/views/Auth/Auth.vue"),
    redirect: "/auth/workBench",

    children: [{
        path: "permissionSettings",
        name: "permissionSettings",
        // 权限设置
        component: () => import("@/modules/permissionSettings/permissionSettings.vue"),
        redirect: "/auth/permissionSettings/roleManagement",
        children: [{
            path: "roleManagement",
            name: "roleManagement",
            meta: {
              name: ["权限设置", "角色管理"]
            },
            // 角色管理
            component: () => import("@/modules/permissionSettings/roleManagement/roleManagement.vue")
          },
          {
            path: "UserManagement",
            name: "UserManagement",
            meta: {
              name: ["权限设置", "用户管理"]
            },
            // 用户管理
            component: () => import("@/modules/permissionSettings/UserManagement/UserManagement.vue")
          },
        ]
      },
      {
        path: "workBench",
        name: "workBench",
        // 工作台
        component: () => import("@/modules/workBench/workBench.vue"),
        redirect: "/auth/workBench/payRollManagement",
        children: [{
            path: "payRollManagement",
            name: "payRollManagement",
            meta: {
              name: ["工作台", "主数据管理"]
            },
            // 主数据管理
            component: () => import("@/modules/workBench/payRollManagement/payRollManagement.vue")
          },
          {
            path: "payRollManagementDetail",
            name: "payRollManagementDetail",
            meta: {
              name: ["工作台", "主数据管理", "查看记录"]
            },
            // 主数据管理
            component: () => import("@/modules/workBench/payRollManagementDetail/payRollManagementDetail.vue")
          }
        ]
      },
    ],
  },
  {
    path: "/qrCode",
    name: "qrCode",
    // 测试二维码
    // 用户管理
    component: () => import("@/modules/qrCode/qrCode.vue")
  },
]


const router = new VueRouter({
  routes
});
router.beforeEach((to, from, next) => {
  let isLogin = localStorage.getItem("token");
  console.log(to, from);
  if (isLogin) {
    // 如果存在token 放行
    next();
  } else {
    //如果用户token不存在且 当前页面就是 则放行
    if (to.path === "/login") {
      next();
    } else {
      // 当前页面不是登录页 则直接跳转到页面
      next("/login");
    }
  }
});

export default router